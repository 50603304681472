<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import sectorsTable from './components/sectorsTable';
export default {
  page: {
    title: "Secteurs",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader , sectorsTable},
  data() {
    return {
      tableData: [],
      title: "Secteurs",
      services: [],
      items: [
        {
          text: "Infrastructures",
          href: "javascript:void(0)"
        },
        {
          text: "Secteurs",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "address", sortable: true },
        { key: "analytic_account", sortable: true },
      ]
    };
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <sectors-table/>
    </div>
  </Layout>
</template>